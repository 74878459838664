// REACT
import React from "react"
// MUI
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

// STYLES
const useStyles = makeStyles({
  heading: {
    fontSize: "1.8rem",
    fontWeight: 600,
  },
  tableContainer: {
    display: "block",
    maxWidth: 600,
    marginTop: "2rem",
    width: "100%",
  },
  table: {
    marginBottom: "0rem",
  },
})

// COMPONENT FUNCTION
export const SpecificationsTable = ({
  specifications,
  header,
  dropSectionTitle = false,
}) => {
  // STYLE OBJECT
  const classes = useStyles()

  // COMPONENT FUNCTION
  return (
    <>
      {dropSectionTitle ? null : (
        <Typography variant="h2" className={classes.heading}>
          Specifications
        </Typography>
      )}
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table
          size="small"
          padding="normal"
          className={classes.table}
          aria-label="Specifications Table"
        >
          {header ? (
            <TableHead>
              <TableRow>
                {header.map(head => (
                  <TableCell align="right">{head}</TableCell>
                ))}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {Object.keys(specifications).map(key => (
              <TableRow key={key} hover>
                <TableCell style={{ fontSize: "1.2rem", paddingLeft: "1rem" }}>
                  {key}
                </TableCell>
                {specifications[key].map(value => (
                  <TableCell
                    style={{ fontSize: "1.2rem" }}
                    key={key.concat(value)}
                    align="right"
                  >
                    {value}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
