// REACT
import React from "react"
// MUI
import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageBlock } from "../components/imageBlock"
import { ImageWithCaption } from "../components/imageWithCaption"
import { SpecificationsTable } from "../components/specificationsTable"

// STYLES
const useStyles = makeStyles({
  heading: {
    fontSize: "1.8rem",
    fontWeight: 600,
    marginTop: "4rem",
  },
})

// COMPONENT FUNCTION
const DinghiesPage = () => {
  // STYLE OBJECT
  const classes = useStyles()

  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      alcarBoatsMiniSkiffDinghy: file(
        relativePath: { eq: "alcar-boats-mini-skiff-dinghy.jpg" }
      ) {
        ...BsmFluidImage
      }
      alcarBoatsSandpiperDinghy: file(
        relativePath: { eq: "alcar-boats-sandpiper-dinghy.jpg" }
      ) {
        ...BsmFluidImage
      }
      alcarBoatsSeaSledDinghy: file(
        relativePath: { eq: "alcar-boats-sea-sled-dinghy.jpg" }
      ) {
        ...BsmFluidImage
      }
    }
  `)

  // SPECIFICATIONS
  const specifications = {
    Sandpiper: {
      Length: ["7'"],
      Beam: ['49"'],
      Weight: ["90 lbs"],
      "Transom Height": ['20"'],
      "Max HP": ["2"],
      "Load Capacity": ["340 lbs"],
    },
    SeaSled: {
      Length: ["8'"],
      Beam: ['51"'],
      Weight: ["100 lbs"],
      "Transom Height": ['20"'],
      "Max HP": ["2"],
      "Load Capacity": ["528 lbs"],
    },
    MiniSkiff: {
      Length: ["9'"],
      Beam: ['57"'],
      Weight: ["125 lbs"],
      "Transom Height": ['20"'],
      "Max HP": ["4"],
      "Load Capacity": ["542 lbs"],
    },
  }

  // DINGHIES
  const dinghies = ["Sandpiper", "Sea Sled", "Mini Skiff"]

  // COMPONENT
  return (
    <Layout title="Alcar Boats - Dinghies">
      <PageTitle title="ALCAR DINGHIES" />

      <Typography paragraph>
        Alcar fiberglass dinghies are designed with functionality and durability
        in mind. Level foam floatation and non-skid flooring exceed the highest
        recommended safety standards. Available in three stylish models, you’ll
        be sure to find one that meets your needs and safely transports friends
        and family from the dock to your boat.
      </Typography>

      {dinghies.map(dinghy => {
        return (
          <div key={dinghy}>
            <Typography variant="h2" className={classes.heading}>
              {dinghy}
            </Typography>
            <ImageBlock>
              <ImageWithCaption
                fluid={
                  data[`alcarBoats${dinghy.replace(" ", "")}Dinghy`]
                    .childImageSharp.fluid
                }
                caption={`Alcar ${dinghy} Dinghy`}
              />
            </ImageBlock>
            <SpecificationsTable
              specifications={specifications[`${dinghy.replace(" ", "")}`]}
              dropSectionTitle
            />
          </div>
        )
      })}
    </Layout>
  )
}

export default DinghiesPage
